import React from "react";
import "./assets/css/styles.scss";
import { Link } from "gatsby";
export default function CreateAccount({ text, color, link }) {
  const styles1 = {
    maxWidth: "230px",
    position: "absolute",
    top: "550px",
    background: color,
  };
  return (
    <div className="CreateAccount" style={styles1}>
      <h1>{text}</h1>
      <button>
        <Link to={link}>Join for free</Link>
      </button>
    </div>
  );
}
