import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Patient from "./patient.js";
import "./assets/css/style.scss";
import { useStaticQuery, graphql } from "gatsby";
import Joincom from "../../components/misc/CreateAccount.js";

export default function FAQ() {
  const [swaplink, setswaplink] = useState("login");
  useEffect(() => {
    document.body.classList.remove("faq-color-red");
  }, []);

  const data = useStaticQuery(graphql`
    {
      allCosmicjsFaqs {
        edges {
          node {
            slug
            metadata {
              heading
              subheading
              expert_faqs {
                data {
                  question
                  answer
                }
              }
              patients_faqs {
                data {
                  question
                  answer
                }
              }
            }
          }
        }
      }
    }
  `);

  var content_patient;
  var content_expert;
  var content_header;

  data.allCosmicjsFaqs.edges.map((x, y) => {
    switch (x.node.slug) {
      case "patients":
        content_patient = x.node.metadata.patients_faqs.data;
        break;
      case "experts":
        content_expert = x.node.metadata.expert_faqs.data;
        break;
      case "header":
        content_header = x.node.metadata;
        break;
    }
  });

  var changeColor = (e) => {
    if (e.target.innerText === "For Experts") {
      setswaplink("expert");
      document.body.classList.add("faq-color-red");
    } else {
      document.body.classList.remove("faq-color-red");
      setswaplink("login");
    }
  };
  return (
    <div className="FAQ">
      <div className="container">
        <div className="heaidng">
          <h1>{content_header.heading}</h1>
          <p>{content_header.subheading}</p>
        </div>
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          onClick={changeColor}
        >
          <Tab eventKey="home" title="For Patients">
            <Patient content={content_patient} />
          </Tab>
          <Tab eventKey="profile" title="For Experts">
            <Patient content={content_expert} />
          </Tab>
        </Tabs>
        <Joincom
          color="#2355f0"
          link={swaplink == "login" ? "login" : "expert-signup"}
          text="Create an account 
and get connected 
with patients"
        />
      </div>
    </div>
  );
}
